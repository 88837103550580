import { createApp } from 'vue'
import App from './App.vue'
import './assets/style.css'
import { createPinia } from 'pinia'
import {createRouter, createWebHashHistory} from "vue-router";

import TourMain from './pages/tourMain.vue';
import TourDetail from './pages/tourDetail.vue';
import TourPilotDetail from './pages/tourPilotDetail.vue';
import TourSquadrons from './pages/tourSquadrons.vue';
import TourSquadronDetail from './pages/tourSquadronDetail.vue';
import LiveMap from './pages/liveMap.vue';

const routes = [
  { path: '/', component: TourMain },
  { path: '/roster', component: TourDetail },
  { path: '/live', component: LiveMap },
  { path: '/pilot/:id', component: TourPilotDetail },
  { path: '/squadrons', component: TourSquadrons },
  { path: '/squadrons/:id', component: TourSquadronDetail }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

createApp(App)
    .use(createPinia())
    .use(router)
    .mount('#app')
