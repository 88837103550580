<script setup>
import { onMounted, watch } from 'vue'
import { useCoEStore } from './../stores/CoEStore';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia'


const coeStore = useCoEStore()
const route = useRoute();
const router = useRouter();

const { selectedTour } = storeToRefs(coeStore)


onMounted(() => {
  coeStore.getTourDetail();
  coeStore.getSquadron(route.params.id);
})

watch(selectedTour, () => coeStore.getSquadron(route.params.id));
    
const navigate = (id) => {
  router.push('/pilot/' + id)
}

</script>

<template>

<div class="flex flex-row w-full p-4 mb-2" v-if="coeStore.squadron && coeStore.tourDetail">
        <div class="text-white mx-auto text-center">
            <h2 class="text-4xl">{{ coeStore.squadron.Name }}</h2>
            Results for {{  coeStore.tourDetail.Name }}
        </div>
    </div>
        
    <div class="flex flex-col md:flex-row" v-if="coeStore.squadron">
        <div class="card flex flex-col w-full md:w-1/2 p-4 mb-2 md:mr-1">

            <div class="flex flex-row">
                <div class="flex-auto">Sorties:</div>
                <div class="w-10 text-right">{{ coeStore.squadron.TourData.NumberOfSorties  }}</div>
            </div>

            <div class="flex flex-row">
                <div class="flex-auto">Total AA kills:</div>
                <div class="w-15 text-right">{{  coeStore.squadron.TourData.NumberOfAirVictories  }}</div>
            </div>

            <div class="flex flex-row">
                <div class="flex-auto">Total AG kills:</div>
                <div class="w-10 text-right">{{ coeStore.squadron.TourData.NumberOfGroundVictories }}</div>
            </div>


        </div>

        <div class="card flex flex-col w-full md:w-1/2 p-4 mb-2 md:ml-1">

            <div class="flex flex-row">
                <div class="flex-auto">Score:</div>
                <div class="w-10 text-right">{{ coeStore.squadron.TourData.Score }}</div>
            </div>

            <div class="flex flex-row">
                <div class="flex-auto">Effectiveness:</div> 
                <div class="w-10 text-right">{{ (((coeStore.squadron.TourData.Score) / (coeStore.squadron.TourData.NumberOfSorties == 0? 1: coeStore.squadron.TourData.NumberOfSorties)) / 10).toFixed(2) }}</div>
            </div>

            <div class="flex flex-row">
                <div class="flex-auto">Planes lost:</div>
                <div class="w-10 text-right">{{  coeStore.squadron.TourData.NumberOfTimesShotDown  }}</div>
            </div>

            <div class="flex flex-row">
                <div class="flex-auto">Times bailed:</div>
                <div class="w-15 text-right">{{  coeStore.squadron.TourData.NumberOfTimesBailed }}</div>
            </div>

        </div>
    </div>

        <div class="card flex flex-row w-full p-4 mb-2">
            <div class="w-8">#</div>
            <div class="flex-auto">Handle</div>
            <div class="w-20 text-center text-white">Sorties</div>
            <div class="w-20 text-center text-white hidden md:block">K/D</div>
            <div class="w-20 text-center text-white hidden md:block">AA</div>
            <div class="w-20 text-center text-white hidden md:block">AG</div>
            <div class="w-20 text-center text-white hidden md:block">Accuracy</div>
            <div class="w-20 text-center text-white hidden md:block">Killed</div>
            <div class="w-20 text-center text-white hidden md:block">Bailed</div>
            <div class="w-20 text-center text-white hidden md:block">Flight time</div>
            <div class="w-20 text-right text-white">Score</div>
        </div>

      <div class="flex flex-col  w-full" v-for="(pilot, index) in coeStore.squadron?.Members.filter(x => x.Handle)" :key="`${pilot.UserId}_${index}`">
        <div class="card flex flex-row justify-between w-full p-4 mb-2 hover:bg-gray-800" @click="() => navigate(pilot.UserId)">
            <div class="w-8">{{ index + 1 }}.</div>
            <div class="flex-auto">{{ pilot.Handle }}</div>
            <div class="w-20 text-center text-white">{{ pilot.NumberOfSorties }}</div>
            <div class="w-20 text-center text-white hidden md:block">{{ pilot.AirKillDeathRatio }}</div>
            <div class="w-20 text-center text-white hidden md:block">{{ pilot.NumberOfAirVictories }}</div>
            <div class="w-20 text-center text-white hidden md:block">{{ pilot.NumberOfGroundVictories }}</div>
            <div class="w-20 text-center text-white hidden md:block">{{ pilot.Accuracy }}%</div>
            <div class="w-20 text-center text-white hidden md:block">{{ pilot.NumberOfTimesShotDown }}</div>
            <div class="w-20 text-center text-white hidden md:block">{{ pilot.NumberOfTimesBailed }}</div>
            <div class="w-20 text-center text-white hidden md:block">{{ coeStore.duration(pilot.FlightTimeTotal) }}</div>
            <div class="w-20 text-right text-white">{{ coeStore.formatRating(pilot.Rating) }}</div>
        </div>
      </div>

</template>