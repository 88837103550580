<script setup>
import { defineProps, ref } from 'vue'

import SortieDetail from '@/components/SortieDetail/sortieDetail.vue';
import CoalitionFlag from '@/components/CoalitionFlag/coalitionFlag.vue';

const props = defineProps(['sorties']);
const selectedFlight = ref(null);

</script>
<template>

    <div class="card flex flex-row w-full p-4 mt-2 border-b-4 border-zinc-700">
        <div class="w-10 text-white">#</div>
        <div class="flex-auto text-white">Sorties</div>
        <div class="w-14 text-white text-center">AA</div>
        <div class="w-14 text-white text-center">AG</div>
        <div class="text-right w-8 text-white">Status</div>
    </div>

    <div class="flex flex-col w-full" v-for="(sortie, index) in props.sorties.filter(x => x.Status != 'not_takeoff')"
        :key="`virtuallife${index}`">
        <div class="card w-full p-4 px-2 border-t-2 mt-2 border-zinc-700 hover:bg-zinc-600 cursor-pointer"
            :class="{'bg-zinc-600': selectedFlight == sortie}"
            @click="selectedFlight == sortie ? selectedFlight = null : selectedFlight = sortie">

            <div class="flex flex-row">
                <div class="w-12">
                    <div class="w-8">
                        <CoalitionFlag :country="sortie.Country"></CoalitionFlag>
                    </div>
                </div>
                <div class="flex-auto self-center">{{ sortie.Aircraft }}</div>
                <div class="w-14 text-center font-bold">{{ sortie.NumberOfAirVictories }}</div>
                <div class="w-14 text-center font-bold">{{ sortie.NumberOfGroundVictories }}</div>
                <div class="text-right w-8">
                    <div class="rounded-full bg-neutral-900">

                        <div v-if="sortie.Status == 'shotdown' || sortie.Status == 'crashed'">
                            <img src="@/assets/status/died_self.png" v-if="sortie.PilotStatus == 'dead'" alt="Pilot died" title="Pilot died" />
                            <img src="@/assets/status/destroyed.png" v-else alt="Destroyed" title="Destroyed" />
                        </div>

                        <div v-else>
                            <img src="@/assets/status/landed.png" v-if="sortie.Status == 'landed'" alt="Landed" title="Landed" />
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <div v-if="selectedFlight == sortie">
            <SortieDetail :sortie="sortie"></SortieDetail>
        </div>
    </div>
</template>