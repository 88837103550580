<script setup>
import { onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useCoEStore } from './../stores/CoEStore';
import { useRoute } from 'vue-router';

import VirtualLife from '@/components/VirtualLife/virtualLife.vue';
import StatBubble from '@/components/StatBubble/statBubble.vue';

const coeStore = useCoEStore()
const route = useRoute();

const { selectedTour } = storeToRefs(coeStore)

onMounted(() => {
    coeStore.getTourDetail();
    coeStore.getPilotDetail(route.params.id);
    coeStore.getPilotLives(route.params.id);

});

watch(selectedTour, () => {
    coeStore.getTourDetail();
    coeStore.getPilotDetail(route.params.id);
    coeStore.getPilotLives(route.params.id);
})

</script>

<template>
    <div class="flex flex-row w-full p-4 mb-2" v-if="coeStore.pilotDetail">
        <div class="text-white mx-auto text-center">
            <h2 class="text-4xl">{{ coeStore.pilotDetail.Handle }}</h2>
            Results for {{  coeStore.tourDetail.Name }}<br/>
        </div>
    </div>

    <div class="flex p-4 mb-2" v-if="coeStore.pilotDetail">
        <div class="mx-auto">
            <div class="flex w-full flex-wrap">


                <img v-if="coeStore.pilotDetail.NumberOfSorties > 0 && coeStore.tourDetail.Name == 'Tour 2'" src="@/assets/awards/ribbons/2023_tour2.png" title="Awarded for participating in Tour 2 - 2023" class="ml-2 mr-2 mt-2" />

                <img v-if="coeStore.pilotDetail.AirVictoryStreakMax >= 3 && coeStore.pilotDetail.AirVictoryStreakMax < 5" src="@/assets/awards/ribbons/sortie-3-aa.png" title="Awarded for destroying more than 3 air targets in one life" class="ml-2 mr-2 mt-2" />
                <img v-if="coeStore.pilotDetail.AirVictoryStreakMax >= 5 && coeStore.pilotDetail.AirVictoryStreakMax < 10" src="@/assets/awards/ribbons/sortie-5-aa.png" title="Awarded for destroying more than 5 air targets in one life" class="ml-2 mr-2 mt-2"/>
                <img v-if="coeStore.pilotDetail.AirVictoryStreakMax >= 10 && coeStore.pilotDetail.AirVictoryStreakMax < 15" src="@/assets/awards/ribbons/sortie-10-aa.png" title="Awarded for destroying more than 10 air targets in one life" class="ml-2 mr-2 mt-2"/>
                <img v-if="coeStore.pilotDetail.AirVictoryStreakMax >= 15" src="@/assets/awards/ribbons/sortie-15-aa.png" title="Awarded for destroying more than 15 air targets in one life" class="ml-2 mr-2 mt-2" />

                <img v-if="coeStore.pilotDetail.GroundVictoryStreakMax >= 10 && coeStore.pilotDetail.GroundVictoryStreakMax < 5" src="@/assets/awards/ribbons/sortie-3-ag.png" title="Awarded for destroying more than 10 groundtargets in one life" class="ml-2 mr-2 mt-2" />
                <img v-if="coeStore.pilotDetail.GroundVictoryStreakMax >= 20 && coeStore.pilotDetail.GroundVictoryStreakMax < 10" src="@/assets/awards/ribbons/sortie-5-ag.png" title="Awarded for destroying more than 20 groundtargets targets in one life" class="ml-2 mr-2 mt-2" />
                <img v-if="coeStore.pilotDetail.GroundVictoryStreakMax >= 40 && coeStore.pilotDetail.GroundVictoryStreakMax < 15" src="@/assets/awards/ribbons/sortie-10-ag.png" title="Awarded for destroying more than 40 groundtargets in one life" class="ml-2 mr-2 mt-2" />
                <img v-if="coeStore.pilotDetail.GroundVictoryStreakMax >= 50" src="@/assets/awards/ribbons/sortie-15-ag.png" title="Awarded for destroying more than 50 groundtargets in one life" class="ml-2 mr-2 mt-2" />

                <img v-if="coeStore.pilotDetail.NumberOfAirVictories >= 10 && coeStore.pilotDetail.NumberOfAirVictories < 25" src="@/assets/awards/ribbons/10-aa.png" title="Awarded for destroying more than 10 air targets in one tour" class="ml-2 mr-2 mt-2" />
                <img v-if="coeStore.pilotDetail.NumberOfAirVictories >= 25 && coeStore.pilotDetail.NumberOfAirVictories < 50" src="@/assets/awards/ribbons/25-aa.png" title="Awarded for destroying more than 25 air targets in one tour" class="ml-2 mr-2 mt-2" />
                <img v-if="coeStore.pilotDetail.NumberOfAirVictories >= 50 && coeStore.pilotDetail.NumberOfAirVictories < 100" src="@/assets/awards/ribbons/50-aa.png" title="Awarded for destroying more than 50 air targets in one tour" class="ml-2 mr-2 mt-2" />
                <img v-if="coeStore.pilotDetail.NumberOfAirVictories >= 100 && coeStore.pilotDetail.NumberOfAirVictories < 250" src="@/assets/awards/ribbons/100-aa.png" title="Awarded for destroying more than 100 air targets in one tour" class="ml-2 mr-2 mt-2" />
                <img v-if="coeStore.pilotDetail.NumberOfAirVictories >= 250 && coeStore.pilotDetail.NumberOfAirVictories < 500" src="@/assets/awards/ribbons/250-aa.png" title="Awarded for destroying more than 250 air targets in one tour" class="ml-2 mr-2 mt-2" />
                <img v-if="coeStore.pilotDetail.NumberOfAirVictories >= 500 && coeStore.pilotDetail.NumberOfAirVictories < 1000" src="@/assets/awards/ribbons/500-aa.png" title="Awarded for destroying more than 500 air targets in one tour" class="ml-2 mr-2 mt-2" />
                <img v-if="coeStore.pilotDetail.NumberOfAirVictories >= 1000" src="@/assets/awards/ribbons/1000-aa.png" title="Awarded for destroying more than 1000 air targets" class="ml-2 mr-2 mt-2" />

                <img v-if="coeStore.pilotDetail.NumberOfGroundVictories >= 10 && coeStore.pilotDetail.NumberOfGroundVictories < 25" src="@/assets/awards/ribbons/10-ag.png" title="Awarded for destroying more than 10 ground targets in one tour" class="ml-2 mr-2 mt-2" />
                <img v-if="coeStore.pilotDetail.NumberOfGroundVictories >= 25 && coeStore.pilotDetail.NumberOfGroundVictories < 50" src="@/assets/awards/ribbons/25-ag.png" title="Awarded for destroying more than 25 ground targets in one tour" class="ml-2 mr-2 mt-2" />
                <img v-if="coeStore.pilotDetail.NumberOfGroundVictories >= 50 && coeStore.pilotDetail.NumberOfGroundVictories < 100" src="@/assets/awards/ribbons/50-ag.png" title="Awarded for destroying more than 50 ground targets in one tour" class="ml-2 mr-2 mt-2" />
                <img v-if="coeStore.pilotDetail.NumberOfGroundVictories >= 100 && coeStore.pilotDetail.NumberOfGroundVictories < 250" src="@/assets/awards/ribbons/100-ag.png" title="Awarded for destroying more than 100 ground targets in one tour" class="ml-2 mr-2 mt-2" />
                <img v-if="coeStore.pilotDetail.NumberOfGroundVictories >= 250 && coeStore.pilotDetail.NumberOfGroundVictories < 500" src="@/assets/awards/ribbons/250-ag.png" title="Awarded for destroying more than 250 ground targets in one tour" class="ml-2 mr-2 mt-2" />
                <img v-if="coeStore.pilotDetail.NumberOfGroundVictories >= 500 && coeStore.pilotDetail.NumberOfGroundVictories < 1000" src="@/assets/awards/ribbons/500-ag.png" title="Awarded for destroying more than 500 ground targets in one tour" class="ml-2 mr-2 mt-2" />
                <img v-if="coeStore.pilotDetail.NumberOfGroundVictories >= 1000" src="@/assets/awards/ribbons/1000-ag.png" title="Awarded for destroying more than 1000 ground targets in one tour" class="ml-2 mr-2 mt-2" />

            </div>
        </div>
    </div>    
        
    <div class="flex flex-col md:flex-row" v-if="coeStore.pilotDetail">
        <div class="card flex flex-col w-full md:w-1/2 p-2 mb-2 md:mr-1">

            <div class="bg-neutral-700 p-1 rounded-md mb-1">
                <StatBubble label="Sorties" :value="coeStore.pilotDetail.NumberOfSorties" :small="true"></StatBubble>
            </div>

            <div class="bg-neutral-700 p-1 rounded-md mb-1">
                <StatBubble label="Total AA kills" :value="coeStore.pilotDetail.NumberOfAirVictories" :small="true"></StatBubble>
            </div>

            <div class="bg-neutral-700 p-1 rounded-md mb-1">
                <StatBubble label="Total AG kills" :value="coeStore.pilotDetail.NumberOfGroundVictories" :small="true"></StatBubble>
            </div>

            <div class="bg-neutral-700 p-1 rounded-md mb-1">
                <StatBubble label="Total AA assists" :value="coeStore.pilotDetail.NumberOfAirVictoryAssists" :small="true"></StatBubble>
            </div>

            <div class="bg-neutral-700 p-1 rounded-md mb-1">
                <StatBubble label="Total Friendly kills" :value="coeStore.pilotDetail.NumberOfFriendlyKills" :small="true"></StatBubble>
            </div>

            <div class="bg-neutral-700 p-1 rounded-md mb-1">
                <StatBubble label="Captures" :value="coeStore.pilotDetail.NumberOfCaptures" :small="true"></StatBubble>
            </div>
            
        </div>

        <div class="card flex flex-col w-full md:w-1/2 p-4 mb-2 md:mr-1">

            <div class="flex flex-row">
                <div class="flex-auto"><div class="text-2xl text-white font-semibold">Streak</div></div>
                <div class="w-20 text-right font-bold">now</div>
                <div class="w-20 text-right font-bold">best</div>
            </div>

            <div class="flex flex-row">
                <div class="flex-auto">AA streak:</div>
                <div class="w-20 text-right">
                    {{ coeStore.pilotDetail.AirVictoryStreakCurrent  }}
                </div>
                <div class="w-20 text-right">{{ coeStore.pilotDetail.AirVictoryStreakMax  }}</div>
            </div>

            <div class="flex flex-row">
                <div class="flex-auto">AG streak:</div>
                <div class="w-20 text-right">{{ coeStore.pilotDetail.GroundVictoryStreakCurrent }}</div>
                <div class="w-20 text-right">{{ coeStore.pilotDetail.GroundVictoryStreakMax }}</div>
            </div>
     
        </div>        

        <div class="card flex flex-col w-full md:w-1/2 p-4 mb-2 md:ml-1">

            <div class="bg-neutral-700 p-1 rounded-md mb-1">
                <StatBubble label="Score" :value="coeStore.formatRating(coeStore.pilotDetail.Rating)" :small="true"></StatBubble>
            </div>

            <div class="bg-neutral-700 p-1 rounded-md mb-1">
                <StatBubble label="Accuracy" :value="`${coeStore.pilotDetail.Accuracy}%`" :small="true"></StatBubble>
            </div>
            
            <div class="bg-neutral-700 p-1 rounded-md mb-1">
                <StatBubble label="Effectiveness" :value="(((coeStore.pilotDetail.Rating / 100) / (coeStore.pilotDetail.NumberOfSorties == 0? 1: coeStore.pilotDetail.NumberOfSorties)) / 10).toFixed(2)" :small="true"></StatBubble>
            </div>

            <div class="bg-neutral-700 p-1 rounded-md mb-1">
                <StatBubble label="Planes lost" :value="coeStore.pilotDetail.NumberOfTimesShotDown + coeStore.pilotDetail.NumberOfDeaths" :small="true"></StatBubble>
            </div>

            <div class="bg-neutral-700 p-1 rounded-md mb-1">
                <StatBubble label="Times ditched" :value="coeStore.pilotDetail.NumberOfTimesDitched" :small="true"></StatBubble>
            </div>

            <div class="bg-neutral-700 p-1 rounded-md mb-1">
                <StatBubble label="Times bailed" :value="coeStore.pilotDetail.NumberOfTimesBailed" :small="true"></StatBubble>
            </div>
        </div>
    </div>


    <div class="w-full mt-4">
        <div class="text-center text-2xl font-bold">
            <div v-if="coeStore.pilotLives.length > 0 && coeStore.pilotLives[0].IsAlive">Current life</div>
            <div v-else>Previous lives</div>
        </div>
    </div>

    <div class="flex flex-col w-full" v-for="(life, index) in coeStore.pilotLives" :key="`pilot-life_${index}`">
        <VirtualLife :data="life" />
    </div>
    
        

</template>