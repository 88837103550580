<script setup>
import { onMounted, watch } from 'vue'
import { useCoEStore } from './../stores/CoEStore';
import { useRouter, useRoute } from 'vue-router';
import { storeToRefs } from 'pinia'

const coeStore = useCoEStore()
const router = useRouter();
const route = useRoute();

const { selectedTour } = storeToRefs(coeStore)

const getSlug = val => {
  return val.toLowerCase().trim()
          .replace(/[^\w\s-]/g, '')
          .replace(/[\s_-]+/g, '-')
          .replace(/^-+|-+$/g, '')
}

onMounted(() => {
  coeStore.getSquadrons();
})

watch(selectedTour, () => coeStore.getSquadrons());

const navigate = (squad) => {
  const routeStart = route.path == '/' ? 'tours': route.path;
  const squadId = getSlug(squad.Name);
  router.push(routeStart + '/' + squadId.replace(' ','-').toLowerCase())
}
    
</script>

<template>
    <div class="card flex flex-row w-full p-4 mb-2">
            <div class="flex-auto text-white">Squadron</div>
            <div class="text-white">Members</div>
        </div>

      <div class="flex flex-col w-full" v-for="(squadron, index) in coeStore.squadrons" :key="`squadron_${index}`">
        <div class="card flex flex-row w-full p-4 mb-2 hover:bg-gray-800" @click="() => navigate(squadron)">
            <div class="flex-auto">{{ squadron.Name }}</div>
            <div>{{ squadron.Members.length }}</div>
        </div>
      </div>
</template>