import { defineStore } from 'pinia'
import axios from 'axios';
import { ITour } from '@/Models/ITour';
import { IPilot } from '@/Models/IPilot';
import { ISquadron } from '@/Models/ISquadron';

export const useCoEStore = defineStore('coe', {
  state: () => {
    return { 
      selectedTour: null as unknown as ITour,
      tours: [] as ITour[],
      pilots: [] as IPilot[],
      tourDetail: null,
      tourPilots: [],
      pilotDetail: null,
      pilotSummary: null,
      pilotOverview: null,
      squadrons: [] as ISquadron[],
      squadron: null as unknown as ISquadron,
      pilotLives: []
    }
  },
  
  actions: {

    getTours() {
      axios.get('https://clashofeaglesdata.blob.core.windows.net/stats/tours.json').then(response => {
        this.tours = response.data.reverse();
        this.selectedTour = this.tours[0];
      })
    },
    getSquadrons() {
      const name = this.selectedTour.Name.toLowerCase().replace('-',' ');
      axios.get('https://clashofeaglesdata.blob.core.windows.net/stats/results/' + name + '/squadrons.json').then(response => {
        this.squadrons = response.data as ISquadron[];
      })
    },
    getSquadron(name : string) {
     
      const tour = this.selectedTour.Name.toLowerCase().replace('-',' ');
      axios.get('https://clashofeaglesdata.blob.core.windows.net/stats/results/' + tour + '/squadrons.json').then(response => {
        const slug = (val : string) => val.toLowerCase().trim()
          .replace(/[^\w\s-]/g, '')
          .replace(/[\s_-]+/g, '-')
          .replace(/^-+|-+$/g, '');

        this.squadron = (response.data as ISquadron[]).filter(x => slug(x.Name) == name)[0];

      })
    },   

    duration(seconds : number) {
        const h : number = Math.floor(seconds / 3600);
        const m : number = Math.floor(seconds % 3600 / 60);

        let hourDisplay : string = h.toString();
        let minutesDisplay : string = m.toString();

        if (h < 10) hourDisplay = '0' + h.toString();
        if (m < 10) minutesDisplay = '0' + m.toString();

        return `${hourDisplay}:${minutesDisplay}`
    },

    formatRating(amount : number) : string {
        //return Math.ceil(amount / 100).toFixed(0);
        return Math.abs(amount) > 999 ? (Math.sign(amount)*((Math.abs(amount)))/1000).toFixed(0) + 'K' : (Math.sign(amount)*Math.abs(amount)).toString();
    },

    getPilots() {
      axios.get('https://clashofeaglesdata.blob.core.windows.net/stats/rankedpilots').then(response => {
        this.pilots = response.data.sort((b : IPilot, a : IPilot) => (a.Rating) - (b.Rating));
      })
    },
    getTourDetail() {

      const doWork = () => {
        const name = this.selectedTour.Name.toLowerCase().replace('-',' ');
        axios.get('https://clashofeaglesdata.blob.core.windows.net/stats/results/' + name + '/tour.json').then(response => {
          this.tourDetail = response.data;
        });
        axios.get('https://clashofeaglesdata.blob.core.windows.net/stats/results/' + name + '/roster.json').then(response => {
          this.tourPilots = response.data.sort((b : IPilot, a : IPilot) => (a.Rating) - (b.Rating));
        });    
      }

      if (this.selectedTour == null) 
      {
        this.getTours();
        window.setTimeout(doWork, 500);
      } else {
        doWork();
      }

  
    },

    getPilotDetail(id : number) {

      const doWork = () => {
        const tour = this.selectedTour.Name.toLowerCase();
        axios.get('https://clashofeaglesdata.blob.core.windows.net/stats/results/' + tour.replace('-',' ') + '/pilots/' + id + '/detail.json').then(response => {
          this.pilotDetail = response.data
        }).catch(() => {
          this.pilotDetail = null;
        });   
      }

      if (this.selectedTour == null) 
      {
        this.getTours();
        window.setTimeout(doWork, 500);
      } else {
        doWork();
      }

    },

    getPilotLives(id : number) {

      const doWork = () => {
        const tour = this.selectedTour.Name.toLowerCase();
        axios.get('https://clashofeaglesdata.blob.core.windows.net/stats/results/' + tour.replace('-',' ') + '/pilots/' + id + '/lives.json').then(response => {
          this.pilotLives = response.data.reverse();
        }).catch(() => {
          this.pilotLives = [];
        });   
      }

      if (this.selectedTour == null) 
      {
        this.getTours();
        window.setTimeout(doWork, 500);
      } else {
        doWork();
      }

    },

  },
})