import { defineStore } from 'pinia'
import { filter } from 'lodash'
import { IPilot } from '@/Models/IPilot'
export const useTourCoalitionStore = defineStore('coeTourCoalition', {
  state: () => {
    return { 
      pilots: [] as IPilot[],
      summary: {
        allied: null,
        axis: null
      }
    }
  },
  
  actions: {
    setTourData(tourPilots  : IPilot[]) {
      this.pilots = tourPilots;
    }
  },

  getters: {
    alliedPilots: (state) => filter(state.pilots, (p : IPilot) => p.PreferedCoalition == 1),
    axisPilots: (state) => filter(state.pilots, (p : IPilot) => p.PreferedCoalition == 2)
  }

})