<script setup>
import { useCoEStore } from './../stores/CoEStore';
import { useRouter } from 'vue-router';

import CoalitionFlag from '@/components/CoalitionFlag/coalitionFlag.vue';

const coeStore = useCoEStore()
const router = useRouter();

const navigate = (id) => {
  router.push('pilot/' + id)
}

</script>

<template>
  
  <div v-if="coeStore.tourPilots.length == 0">
    <div class="card w-full p-4 mb-2">
      <div class="text-white" v-if="coeStore.tourDetail">
        <h2 class="text-1xl mx-auto text-center w-full">No information for this tour just yet!<br/>We need to wait to get the data roll in. The stats are updated every 10 hours.</h2>
      </div>
    </div>
  </div>

  <div v-else>

    <div class="w-full text-center p-4 mb-2">
		<div class="text-white">
			<h2 class="text-4xl">Roster for {{ coeStore.tourDetail.Name }}</h2>
		</div>
	</div>

    <div class="card grid grid-cols-12 w-full p-4 mb-2 justify-items-start">
      <div class="col-span-5 md:col-span-8 text-white">Handle</div>
      <div class="col-span-2 md:col-span-1 flex justify-center text-center"></div>
      <div class="col-span-2 md:col-span-1 place-self-center text-white">Captures</div>
      <div class="hidden md:col-span-1 md:block place-self-center text-white">Time flown</div>
      <div class="col-span-2 md:col-span-1 place-self-end text-right text-white">Score</div>
    </div>

    <div class="flex flex-col w-full" v-for="(pilot, index) in coeStore.tourPilots" :key="`${pilot.Id}_${index}`">
      <div class="card grid grid-cols-12 w-full p-4 mb-2 justify-items-start hover:bg-gray-800" @click="() => navigate(pilot.UserId)">
        <div class="col-span-5 md:col-span-8 flex">
          <div class="w-8">{{ index + 1 }}.</div> {{ pilot.Handle }}
        </div>
        <div class="col-span-2 md:col-span-1 flex justify-center text-center">
            <div class="w-8">
              <CoalitionFlag country="101" v-if="pilot.PreferedCoalition == 1"></CoalitionFlag>
              <CoalitionFlag country="201" v-if="pilot.PreferedCoalition == 2"></CoalitionFlag>

              <div v-if="pilot.PreferedCoalition == 0" class="flex justify-center">
                <CoalitionFlag country="101"></CoalitionFlag>
                <CoalitionFlag country="201"></CoalitionFlag>              
              </div>
            </div>
        </div>   
        <div class="col-span-2 md:col-span-1 place-self-center">{{ pilot.NumberOfCaptures }}</div>
        <div class="hidden md:col-span-1 md:block place-self-center">{{ coeStore.duration(pilot.FlightTimeTotal) }}</div>
        <div class="col-span-2 md:col-span-1 place-self-end">{{ coeStore.formatRating(pilot.Rating) }}</div>
      </div>
    </div>
  </div>
</template>