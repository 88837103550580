<script setup>
import { defineProps } from 'vue'

import CoalitionFlag from '@/components/CoalitionFlag/coalitionFlag.vue';
import StatBubble from '@/components/StatBubble/statBubble.vue';
import MissionSorties from '@/components/MissionSorties/missionSorties.vue';
import { useCoEStore } from '@/stores/CoEStore';

const props = defineProps(['data']);
const store = useCoEStore();

</script>

<template>
    <div class="card w-full p-4 px-2 mt-8">

        <div class="flex justify-between gap-2 mb-8">

            <div class="rounded-full bg-neutral-900">
                <div class="flex h-12">
                    <div class="w-1/3">
                        <CoalitionFlag country="101" v-if="props.data.Summary.PreferedCoalition == 1"></CoalitionFlag>
                        <CoalitionFlag country="201" v-if="props.data.Summary.PreferedCoalition == 2"></CoalitionFlag>
                    </div>
                    <div class="self-center">{{ props.data.DateCreated }}</div>
                </div>
            </div>

            <div class="rounded-full bg-neutral-900 h-12">
                <div v-if="!props.data.IsAlive" class="flex pt-1">
                    <img src="@/assets/status/died_self.png" class="w-1/3" alt="Pilot died" title="Pilot died" />
                    <div class="self-center">{{ props.data.DateEnded }}</div>
                </div>
            </div>

        </div>

        <div class="flex flex-wrap">
            <StatBubble label="Air to Air kills" :value="props.data.Summary.NumberOfAirVictories" />
            <StatBubble label="Air to Ground kills" :value="props.data.Summary.NumberOfGroundVictories" />
            <StatBubble label="Age" :value="props.data.AgeInDays" />
            <StatBubble label="Score" :value="props.data.Summary.Score" />
            <StatBubble label="Accuracy" :value="props.data.Summary.Accuracy" />
            <StatBubble label="Sorties" :value="props.data.Summary.NumberOfSorties" />
            <StatBubble label="Bailed" :value="props.data.Summary.NumberOfTimesBailed" />
            <StatBubble label="Shot down" :value="props.data.Summary.NumberOfTimesShotDown" />
            <StatBubble label="Flight time" :value="`${store.duration(props.data.Summary.FlightTime)} hrs`" />
        </div>
    </div>

    <MissionSorties :sorties="props.data.Sorties.filter(x => x.Status != 'not_takeoff')" />

    <div v-if="props.data.IsAlive" class="mt-8 mb-4">
        <div class="w-full mt-4">
            <div class="text-center text-2xl font-bold">Previous lives</div>
        </div>
    </div>
    
</template>