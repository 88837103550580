<script setup>
import { watch } from 'vue'
import { storeToRefs } from 'pinia'

import { useCoEStore } from './../stores/CoEStore';
import { useTourCoalitionStore } from './../stores/TourCoalitionStore';
import { sumBy } from 'lodash';

import AmountDivider from '@/components/AmountDivider/amountDivider.vue';
import PilotRatingList from '@/components/PilotRatingList/pilotRatingList.vue';

const coeStore = useCoEStore();
const tourCoalitionStore = useTourCoalitionStore();

const { tourPilots } = storeToRefs(coeStore)


watch(tourPilots, () => {
	tourCoalitionStore.setTourData(coeStore.tourPilots);
})

const pilotRatingList = [
	{ label: 'Troop transport /', order: 'NumberOfCaptures', scorelabel: 'Captures' },
	{ label: 'Bomber', order: 'RatingHeavy', scorelabel: 'Rating'},
	{ label: 'Fighter', order: 'RatingLight', scorelabel: 'Rating'},
	{ label: 'Jabo', order: 'RatingMedium', scorelabel: 'Rating'},
]

</script>

<template>
	<div class="w-full text-center p-4 mb-2">
		<div class="text-white" v-if="coeStore.tourDetail">
			<h2 class="text-4xl">{{ coeStore.tourDetail.Name }}</h2>
		</div>
	</div>


	<div class="flex flex-wrap gap-0">
		<div class="flex flex-col w-full md:w-1/2">

			<div class="flex flex-col mx-2">
				<AmountDivider label="Active pilots" :value-a="tourCoalitionStore.alliedPilots.length"
					:value-b="tourCoalitionStore.axisPilots.length" />
				
				<AmountDivider label="Air victories"
					:value-a="sumBy(tourCoalitionStore.alliedPilots, (p) => p.NumberOfAirVictories)"
					:value-b="sumBy(tourCoalitionStore.axisPilots, (p) => p.NumberOfAirVictories)" />

				<AmountDivider label="Score" :value-a="sumBy(tourCoalitionStore.alliedPilots, (p) => p.Score)"
					:value-b="sumBy(tourCoalitionStore.axisPilots, (p) => p.Score)" />

			</div>
			

		</div>

		<div class="flex flex-col w-full md:w-1/2">

			<div class="flex flex-col mx-2">
				<AmountDivider label="Sorties flown" :value-a="sumBy(tourCoalitionStore.alliedPilots, (p) => p.NumberOfSorties)"
				:value-b="sumBy(tourCoalitionStore.axisPilots, (p) => p.NumberOfSorties)" />

				<AmountDivider label="Ground victories"
					:value-a="sumBy(tourCoalitionStore.alliedPilots, (p) => p.NumberOfGroundVictories)"
					:value-b="sumBy(tourCoalitionStore.axisPilots, (p) => p.NumberOfGroundVictories)" />

				<AmountDivider label="Flight time" :value-a="sumBy(tourCoalitionStore.alliedPilots, (p) => p.FlightTimeTotal)"
					:value-b="sumBy(tourCoalitionStore.axisPilots, (p) => p.FlightTimeTotal)"
					:display-a="`${coeStore.duration(sumBy(tourCoalitionStore.alliedPilots, (p) => p.FlightTimeTotal))}`"
					:display-b="`${coeStore.duration(sumBy(tourCoalitionStore.axisPilots, (p) => p.FlightTimeTotal))}`" />
			</div>
			

		</div>

		<div class="w-full">
			<AmountDivider label="Fields Captured"
						:value-a="sumBy(tourCoalitionStore.alliedPilots, (p) => p.NumberOfCaptures)"
						:value-b="sumBy(tourCoalitionStore.axisPilots, (p) => p.NumberOfCaptures)" />
		</div>

	</div>

	<div class="flex flex-wrap gap-0">
		<div v-for="(rating, index) in pilotRatingList" :key="`rating_${index}_101`" class="lex flex-col w-full md:w-1/2">
			<PilotRatingList :label="rating.label" :pilots="coeStore.tourPilots" country="101" :order="rating.order" :scorelabel="rating.scorelabel"/>
		</div>		
	</div>

	
</template>