<template>
    <div v-if="props.sortie">

            <div class="flex flex-wrap w-full mb-2">

                <StatBubble label="Fuel" :value="`${props.sortie.Fuel}%`" />
                <StatBubble label="Score" :value="props.sortie.Score" />
                <StatBubble label="Status" :value="props.sortie.AircraftStatus" />
                <StatBubble label="Duration" :value="`${coeStore.duration(props.sortie.FlightTime)} hrs`" />

            </div>

            <div class="m-2">
                <div v-for="(event, index) in props.sortie.Events.filter(x => x.EventType != 'damaged')" :key="`event_${index}`">
                    <div class="bg-zinc-900 grid grid-cols-3 gap-4 p-2 mt-2">
                        <div>
                            <div v-if="event.EventType == 'respawn'">Pilot spawned</div>
                            <div v-if="event.EventType == 'takeoff'">Takeoff</div>
                            <div v-if="event.EventType == 'landed'">Landing</div>
                            <div v-if="event.EventType == 'end'">Mission end</div>
                            <div v-if="event.EventType == 'damaged'">Damaged</div>
                            <div v-if="event.EventType == 'shotdown'">Shot down</div>
                            <div v-if="event.EventType == 'bailout'">Bailed</div>
                            <div v-if="event.EventType == 'killed'">Killed</div>
                            <div v-if="event.EventType == 'wounded'">Wounded</div>
                            <div v-if="event.EventType == 'destroyed'">Destroyed</div>  
                        </div>
                        <div class="place-self-center">&nbsp;{{ event.Target }}</div>
                        <div class="place-self-end text-white font-bold">&nbsp;{{  event.TargetName ?? '-' }}</div>
                    </div>                    

                </div>
            </div>    

        </div>

    
</template>

<script setup>
    import { defineProps } from 'vue'
    import { useCoEStore } from '@/stores/CoEStore'
    import StatBubble from '../StatBubble/statBubble.vue';
    
    const props = defineProps(['sortie'])
    const coeStore = useCoEStore();
</script>