<script setup>
import { onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useCoEStore } from '@/stores/CoEStore';

const coeStore = useCoEStore()
const { selectedTour } = storeToRefs(coeStore)

onMounted(() => {
    coeStore.getTours();
})
watch(selectedTour, (tour) => {
    coeStore.selectedTour = tour;
    coeStore.getTourDetail();
})

</script>
<template>

<div class="container p-4 lg:w-6/12 mx-auto">
    <img src="https://clashofeagles.net/wp-content/uploads/2021/05/cropped-coe_trans-768x768.png" class="w-20 mx-auto"/>
</div>

    <nav>
        <div class="container p-4 lg:w-6/12 mx-auto">

            <div class="flex w-full ">
            <ul class="menu flex gap-8">
                <li><router-link to="/">Overview</router-link></li>
                <li><router-link to="/live">Live</router-link></li>
                <li><router-link to="/roster">Roster</router-link></li>
                <li><router-link to="/squadrons">Squadrons</router-link></li>
            </ul>
            
            <div class="ml-auto align-right flex">
                <select class="ml-auto align-right" v-model="selectedTour">
                    <option v-for="(tour,index) in coeStore.tours" :key="`tourlist_${index}`" :value="tour">{{ tour.Name }}</option>
                </select>
            </div>
        </div>

            <!-- <div class="menu-extras">
                <div class="menu-item"><a class="navbar-toggle" id="isToggle" onclick="toggleMenu()">
                        <div class="lines"><span></span><span></span><span></span></div>
                    </a></div>
            </div> -->
        </div>
    </nav>
</template>

<style scoped>

select {
    color: #000;
    background-color: #727272;
}

nav {
    background-color: #2a2a2a
}

.menu li {
    text-transform: uppercase;
    color:#fff;
    line-height: 1.3;
    font-weight: 400;
    font-size: .9rem;
    letter-spacing: .1rem;
}
</style>