<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useCoEStore } from './../stores/CoEStore';
import ArenaMap from '@/components/ArenaMap/arenaMap.vue';
import { useRouter } from 'vue-router';

import CoalitionFlag from '@/components/CoalitionFlag/coalitionFlag.vue';

const router = useRouter();
const coeStore = useCoEStore();

let players = ref([]);

onMounted(() => {
    
    axios.get('https://clashofeaglesdata.blob.core.windows.net/stats/players.json').then(response => {
		players.value = response.data;
    });
})

const navigate = (id) => {
  router.push('pilot/' + id)
}

</script>

<template>

	<div class="flex flex-col md:flex-row">

		<div class="flex flex-col w-full">
			<div class="text-white text-center mb-8" v-if="coeStore.tourDetail">
				<h2 class="text-4xl">{{ coeStore.tourDetail.Name }} Live situation</h2>
			</div>		
			<div class="w-full p-3 bg-white">
				<ArenaMap></ArenaMap>
			</div>
		</div>
        <div class="md:w-1/3  md:pl-4 mt-8">
            <div class="text-white text-2xl font-bolder" v-if="players.length > 0">Now flying</div>
			<div>
				<div class="card flex flex-row w-full p-4 mt-2 border-b-4 border-zinc-700" v-if="players.length > 0">
					<div class="w-6 text-white">#</div>
					<div class="flex-auto text-white">Handle</div>
					<div class="w-12 text-white text-center">Country</div>
				</div>

				<div class="flex flex-col w-full" v-for="(player, index) in players"
					:key="`online_player_${index}`">
					<div class="card w-full p-4 px-2 border-t-2 mt-2 border-zinc-700 hover:bg-zinc-600 cursor-pointer"
					@click="navigate(player.UserId)">

						<div class="flex flex-row">
							<div class="self-center w-6">{{ index + 1 }}.</div>
							<div class="flex-auto self-center">{{ player.Handle }}</div>
							<div class="w-12">
								<div class="w-8">
									<CoalitionFlag :country="player.Country == 1? '102': '201'"></CoalitionFlag>
								</div>
							</div>
						</div>

					</div>

				</div>

				<div v-if="players.length == 0" class="mt-12">
					Currently no one is playing. So grab your helmet and put on your pilotsuit and hop over to our server and start working those fields!!
				</div>
				<div class="font-extralight text-sm text-white pt-8">This information is updated every 5 minutes.</div>
			</div>
        </div>
    </div>	

	
</template>